.clickable {
  cursor: pointer;
}

.relative {
  position: relative;
}
.notification {
  background: #d8edff !important;
  color: #080707 !important;
}


.btn-search-clear {
  position: absolute;
  right: 0;
  height: 100%;
  outline: 0;
  padding: 0 10px;
}



